/*
Version: 1.0
Author: Symlink Technology
Author URI: https://www.symlinktech.com/.
*/
@import url('https://fonts.googleapis.com/css2?family=Gantari:wght@100;200;300;400;500;600;700;800;900&display=swap');
/*
font-family: 'Gantari', sans-serif;
*/
:root{
	--color01:#6A00E5;
	--color02:#0891ff;
	--color03:#FF8C37;
  --colorLive:#CE2C2C;
	--shades01:#ffffff;
	--shades02:#898989;
	--shades03:#676767;
	--shades04:#3F3F3F;
	--shades05:#393939;
	--shades06:#252525;
	--shades07:#131313;
	--shades08:#000000;
  --veiwWidth:calc(100vw - 17px);
  --padding:calc(calc(var(--veiwWidth) - 1570px) / 2);
}

/* ********|| INITIALIZATION STARTS ||******** */
body, html { width: 100%; line-height:1.4; margin:0 auto !important;padding:0 !important;font-family: 'Gantari', sans-serif; font-weight: 400; -webkit-font-smoothing: subpixel-antialiased;text-shadow: 1px 1px 1px rgba(0,0,0,0.004);font-size: 14px;  color: var(--shades01); background:var(--shades07);position: relative; z-index: 0;}
body.alt-page{color: var(--shades07); background:var(--shades01);}


*:focus{outline: none !important;outline-offset: none !important;outline-offset: 0 !important;}
a {text-decoration: none ;}
a:hover{text-decoration: none;-webkit-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -o-transition: all 0.3s ease; -ms-transition: all 0.3s ease;transition: all 0.3s ease;}
a:focus{ outline: none;text-decoration: none;}
input:focus, label:focus{outline: none !important;outline-offset: none !important;outline-offset: 0 !important;}
/* ********|| INITIALIZATION ENDS ||******** */


/* ********|| PSEUDO ELEMENTS STARTS ||******** */
::selection{ background-color:var(--color01); color:var(--shades01)}
::-moz-selection{ background-color:var(--color01); color:var(--shades01)}
::-webkit-selection{ background-color:var(--color01); color:var(--shades01)}

:-webkit-focus { outline: none !important; }
:-moz-focus { outline: none !important; }
:focus { outline: none !important; }

select:-webkit-focusring { color: var(--shades01) !important;text-shadow: 0 0 0 var(--shades04);}
select:-moz-focusring {color: var(--shades01) !important;text-shadow: 0 0 0 var(--shades04);}
select:focusring {color: var(--shades01) !important; text-shadow: 0 0 0 var(--shades04);}

::input-placeholder{ color:var(--shades05) !important;}
::-moz-input-placeholder{ color:var(--shades05) !important;}
::-webkit-input-placeholder{ color:var(--shades05) !important;}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px var(--shades07) inset;
  -webkit-text-fill-color:var(--shades01);
}

/*::-webkit-scrollbar-track{	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);box-shadow: inset 0 0 6px rgba(0,0,0,0.1);background-color: var(--textMedColor);}
::-webkit-scrollbar{width: 5px;height: 5px;background-color: var(--textMedColor);}
::-webkit-scrollbar-thumb{background-color: var(--secondaryColorAltLight);}*/
/* ********|| PSEUDO ELEMENTS ENDS ||******** */


/* ********|| ADJUSTMENT CLASSES STARTS ||******** */
*,::after,::before{box-sizing: border-box;}
a, button{color:var(--shades01); border: 0; transition: all 0.3s ease;}
a:hover, a:focus{color:var(--shades05);}
p { margin: 0 0 10px; }
hr{margin-top: 20px; margin-bottom: 20px; border: 0; border-top: 1px solid var(--shades04);}

.required:after{content:"*";color:var(--colorLive); font-size:16px; font-weight:600; position: absolute;margin: 0 0 0 3px;}
.separator_dashed{ border-bottom:var(--shades04) 1px dashed;}
.separator_dotted{ border-bottom:var(--shades04) 1px dotted;}
.separator_solid{ border-bottom:var(--shades04) 1px solid;}

.bg-shades01{background-color:var(--shades01) !important;}
.bg-shades02{background-color:var(--shades02) !important;}
.bg-shades03{background-color:var(--shades03) !important;}
.bg-shades04{background-color:var(--shades04) !important;}
.bg-shades05{background-color:var(--shades05) !important;}
.bg-shades06{background-color:var(--shades06) !important;}
.bg-shades07{background-color:var(--shades07) !important;}
.bg-shades08{background-color:var(--shades08) !important;}

/* ********|| ADJUSTMENT CLASSES ENDS ||******** */

.form-control{border-radius: 0;box-shadow: none;border: 0;border-bottom: 1px solid var(--shades03);color: var(--shades01);background-color:transparent;min-width: 50px;min-height: 40px;width: 100%;padding: 6px 0;font-family: inherit;font-size: 16px;}
.form-control:focus{ border-color:var(--shades01);box-shadow: none; background-color: transparent;}
.form-control::-webkit-input-placeholder{ color: var(--shades05);}
.form-control:-moz-placeholder{color:var(--shades05);opacity:  1;}
.form-control::-moz-placeholder{color:var(--shades05); opacity:1;}
.form-control:-ms-input-placeholder{color:var(--shades05);}
.form-control::-ms-input-placeholder{color:var(--shades05);}
textarea.form-control{ resize:none; min-height: 100px;}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{ background-color: var(--shades02); opacity: 0.7; cursor: not-allowed;}

.custom-checkbox{position: relative;padding-left: 24px;margin: 3px 0;display: inline-block;}
.custom-checkbox .custom-checkbox-input{position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;opacity: 0; cursor: pointer;}
.custom-checkbox .custom-checkbox-label{position: relative;margin: 0;line-height: 16px;font-size: 14px;}
.custom-checkbox .custom-checkbox-label:before{content:'';position:absolute;width: 16px;height: 16px;background: var(--shades02);border: 1px solid #ddd;top: 50%;left: -24px; transform: translateY(-50%);border-radius: 2px;}
.custom-checkbox .custom-checkbox-label:after{content: "\f00c";position: absolute;font-family: "Font Awesome 5 Pro";width: 16px;height: 16px;top: 50%;left: -24px;transform: translateY(-50%); font-weight: 500;font-size: 10px;display: none;color: var(--shades02);text-align: center;}
.custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:after{display:block;}
.custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:before{background: var(--shades01);border: 1px solid var(--shades01);}

.custom-radio{position: relative;padding-left: 24px;margin: 3px 0;display: inline-block;}
.custom-radio .custom-radio-input{position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;opacity: 0; cursor: pointer;}
.custom-radio .custom-radio-label{position: relative;margin: 0;line-height: 16px;font-size: 14px;}
.custom-radio .custom-radio-label:before{content:'';position:absolute;width: 16px;height: 16px;background: var(--shades02);border: 1px solid #ddd;top: 50%;left: -24px; transform: translateY(-50%);border-radius: 50%;}
.custom-radio .custom-radio-label:after{content: "\f111";position: absolute;font-family: "Font Awesome 5 Pro";width: 16px;height: 16px;top: 50%;left: -24px;transform: translateY(-50%); font-weight: 600;font-size: 8px;display: none;color: var(--shades02);text-align: center;}
.custom-radio .custom-radio-input:checked ~ .custom-radio-label:after{display:block;}
.custom-radio .custom-radio-input:checked ~ .custom-radio-label:before{background: var(--shades01);border: 1px solid var(--shades01);}


.custom-tab{}
.custom-tab .custom-tab-navigation{padding-left: 0;list-style: none;display: flex;flex-wrap: wrap;justify-content: center;margin: 0 auto 10px;}
.custom-tab .custom-tab-navigation > li{width: auto;margin:5px;position: relative;}
.custom-tab .custom-tab-navigation > li > a{background-color: rgba(255,255,255,0.1);color: var(--shades02);padding: 10px 20px;display: block;font-size: 16px;font-weight: 400;text-transform: capitalize;position: relative;border-radius: 4px;max-width: 300px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}
.custom-tab .custom-tab-navigation > li.active > a,.custom-tab .custom-tab-navigation > li > a:hover{background-color: var(--shades01);}
.custom-tab .custom-tab-content{}
.custom-tab .custom-tab-content .custom-tab-wrap{display: none;background: transparent;padding: 20px 0;}
.custom-tab .custom-tab-content .custom-tab-wrap.active{display: block;}
.custom-tab .custom-tab-content .custom-tab-navigation > li > a{padding: 5px 10px;font-size: 14px;font-weight: 600;}
.custom-tab .custom-tab{margin-top: -20px;}
.custom-tab .custom-tab .custom-tab-navigation{margin-bottom: 0;}
@media (min-width: 576px){.custom-tab .custom-tab-navigation{max-width:620px}}
@media (min-width: 768px){.custom-tab .custom-tab-navigation{max-width:740px}}
@media (min-width: 992px){.custom-tab .custom-tab-navigation{max-width:1140px}}
@media (min-width: 1200px){.custom-tab .custom-tab-navigation{max-width:1200px}}


/* /////////////////////////////////////////////////////////////// */
/*             			   	 	BOOTSTRAP FIX            			   				 */
/* /////////////////////////////////////////////////////////////// */
@media (min-width: 576px){
  :root{
    --padding:15px;
  }
	.container{max-width: none;}
	.modal-dialog{max-width: 700px;}
	.modal-sm{max-width: 400px;}
}
@media (min-width: 768px){
  :root{
    --padding:calc(calc(var(--veiwWidth) - 710px) / 2);
  }
	.container{max-width: 740px;}
}
@media (min-width: 992px){
  :root{
    --padding:calc(calc(var(--veiwWidth) - 930px) / 2);
  }
	.container{max-width: 960px;}
}
@media (min-width: 1200px){
  :root{
    --padding:calc(calc(var(--veiwWidth) - 1140px) / 2);
  }
	.container{max-width: 1170px;}
}
@media (min-width: 1440px){
  :root{
    --padding:calc(calc(var(--veiwWidth) - 1310px) / 2);
  }
	.container{max-width: 1340px;}
}
@media (min-width: 1600px){
  :root{
    --padding:calc(calc(var(--veiwWidth) - 1370px) / 2);
  }
	.container{max-width: 1400px;}
}
@media (min-width: 1900px){
  :root{
    --padding:calc(calc(var(--veiwWidth) - 1570px) / 2);
  }
	.container{max-width: 1600px;}
}

.modal-open {overflow: auto;}
.modal{ overflow-x: hidden; overflow-y: auto;}
.overflow-hidden{overflow:hidden !important;}
.dropdown .dropdown-toggle{padding-right: 16px !important;position: relative;}
.dropdown .dropdown-toggle::after{content: "\f078";font-family: "Font Awesome 5 Pro";font-size: 14px;font-weight: 500;display: block;position: absolute;margin: 0;top: 50%;right: 0;transform: translateY(-50%);border: 0; color: var(--shades01); opacity: 1; margin-top: 2px; transition: all 0.3s ease;} 
.dropdown.show .dropdown-toggle::after{transform: translateY(-50%) rotate(-180deg);}
/*.dropdown .dropdown-menu{display:block;top: 100% !important;left: 50% !important;transform: translate(-50%,50px) !important;visibility: hidden;opacity:0;transition:.5s ease all;}*/
/*.dropdown .dropdown-menu.show{display:block;transform: translate(-50%,0) !important;visibility: visible;opacity:1;transition:.5s ease all;}*/
.dropdown-menu .dropdown-item{display: flex;padding: 5px 15px;color: var(--shades05);}
.dropdown-menu .dropdown-item:focus,.dropdown-menu .dropdown-item:hover,.dropdown-menu .dropdown-item:active{background: var(--shades01);color: var(--shades02);}
.mega-dropdown{position:static !important;}
.mega-dropdown .mega-dropdown-menu{display:block;width: 100%;top: auto !important;left: 50% !important;transform: translate(-50%,50px) !important;padding: 20px 40px;visibility: hidden;opacity:0;transition:.5s ease all;}
.mega-dropdown .mega-dropdown-menu.show{display:block;transform: translate(-50%,0) !important;visibility: visible;opacity:1;transition:.5s ease all;}

/*////////////////////////////////////////////////////////////////*/
/*             		          	 HOME PAGE      	      				  	*/
/*////////////////////////////////////////////////////////////////*/

/*
font-family: 'Gantari', sans-serif;
*/

/* ********|| HEADER STARTS ||******** */
header{position: sticky; top: 0; left: 0; z-index: 9;width: 100%;height: 96px;display: flex; align-items: center;background: var(--shades08);transition: all 0.3s ease;}
/* header:before{content: ''; position: absolute;width:100%; height:100%; top: 0;left: 0;background: var(--shades07);z-index: -1; opacity: 0;transition: all 0.3s ease;} */
header .container{display: flex; align-items: center;justify-content: space-between;}
header .brand{position: relative; display: flex;align-items: center;width: 200px;}
header .brand .logo{display: block; height: 30px;}
header .brand .logo img{width: 100%;height: 100%;object-fit: contain;}
header .navigation{position: relative; margin:0 auto;display: flex;align-items: center;}
header .nav-menu{display: flex; align-items: center;}
header .nav-menu .nav-item{position: relative;}
header .nav-menu .nav-link{padding: 0 16px;line-height: 1.5;font-size: 16px;color: var(--shades02);height: 96px; display: flex; align-items: center;position: relative;}
header .nav-menu .nav-link::after{content: ''; position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); border-bottom: 2px solid var(--shades01); width: 20px; opacity: 0; transition: all 0.3s ease;}
header .nav-menu .nav-link:hover, header .nav-menu .nav-item .nav-link.active{color: var(--shades01);}
header .nav-menu .nav-link:hover:after, header .nav-menu .nav-item .nav-link.active:after{opacity: 1;}
header .btn-search{padding: 0 16px;line-height: 1.5;font-size: 16px; font-weight: 400; color: var(--shades03); background-color: transparent; height: 52px; display: flex; justify-content: center; align-items: center; border: 0;}
header .btn-search:hover{color: var(--shades01);}
header .header-options{display: flex; align-items: center; justify-content: flex-end;width: 200px;}
header .header-options .btn-action{padding: 0 16px;line-height: 1.5;font-size: 14px; font-weight: 400; color: var(--shades01); background-color: transparent; height: 52px; display: flex; justify-content: center; align-items: center; border: 0; border-radius: 4px;}
header .header-options .btn-action.btn-membership{background-color: var(--color01);}
header .header-options .btn-action+.btn-action{margin-left: 20px;}

header.header-alt .btn-back{width: 48px;height: 48px;display: flex;justify-content: center;align-items: center;border-radius: 50%;background-color: var(--shades07);padding: 0;font-size: 20px;color: var(--shades01);}
header.header-alt .btn-back:hover{background-color: var(--color01);}

.header-filler{position: relative; height: 96px; width: 100%;}

.scroll-down header{transform: translate3d(0, -100%, 0);backdrop-filter: blur(12px);}
.scroll-down header:before{opacity:0.8;}
.scroll-up header{transform: none;backdrop-filter: blur(12px);}
.scroll-up header:before{opacity:0.8;}

body.alt-page header{border: none;}
body.alt-page header .brand-alt{position: relative;top: auto;left: auto;transform: none;}
body.alt-page header .brand-alt .logo img{filter: invert(1);}

.authentication-panel{position: relative; z-index: 10;}
.authentication-panel .panel-backdrop{position: fixed;top: 0;right: 0;width: 100%;height: 100%;background-color: var(--shades08);z-index: 1;opacity: 0; visibility: hidden;transition: all 0.3s ease;}
.authentication-panel .panel-content{position: fixed;top: 0;right: 0;background-color: var(--shades07);box-shadow: 0px 0px 148px rgb(0 0 0 / 50%);z-index:2;width: 100%;max-width: 560px;height: 100%;padding:120px 60px;transform: translateX(120%);transition: all 0.3s ease;}
.authentication-panel .panel-header{ margin: 0 0 60px;}
.authentication-panel .panel-close{border: 0;padding: 2px 0 0;margin: 0;width: 40px;height: 40px;display: flex;justify-content: center;align-items: center;font-size: 16px;border-radius: 50%;background-color: var(--shades05);position: absolute;top: 30px;right: 30px;z-index: 3;color: var(--shades01);}
.authentication-panel .panel-back{border: 0;padding: 2px 0 0;margin: 0;width: 40px;height: 40px;display: flex;justify-content: center;align-items: center;font-size: 16px;border-radius: 50%;background-color: var(--shades05);position: absolute;top: 30px;left: 30px;z-index: 3;color: var(--shades01);}
.authentication-panel .panel-title{font-size: 32px;line-height: 1.2;color: var(--shades01); font-weight: 300;}
.authentication-panel .panel-text{font-size: 16px;line-height: 1.4;color: var(--shades02); font-weight: 300; margin: 10px 0 0;}
.authentication-panel .panel-body{position: relative;}
.authentication-panel .form-content{width: 100%;max-width: calc(100% - 20px);}
.authentication-panel .form-content .form-group{margin: 0 0 50px;}
.authentication-panel .form-content .input-style{position: relative;z-index: 0;}
.authentication-panel .form-content .float-label{position: absolute;z-index: -1;top: 50%;left: 0;transform: translateY(-50%);font-size: 16px;color: var(--shades02); transition: all 0.3s ease; font-weight: 300;}
.authentication-panel .form-content .form-control{z-index: 1; font-size: 24px; font-weight: 300;height: 40px;line-height: 1;padding: 0;}
.authentication-panel .form-content .form-control:focus{border-color: var(--shades01);color: var(--shades01);}
.authentication-panel .form-content .form-control:focus ~ .float-label{font-size: 13px;margin: -24px 0 0;}
.authentication-panel .form-content .input-style.hasValue .float-label{font-size: 13px;margin: -24px 0 0;}
.authentication-panel .form-content .btn-password{position: absolute;bottom: 0;right: 0;width: 40px;height: 40px;display: flex;justify-content: center;align-items: center;background-color: transparent;color: var(--shades02);}
.authentication-panel .form-content .btn-password.active{color: var(--shades01);}
.authentication-panel .form-content .form-action{margin: 40px 0 100px;display: flex;align-items: center;opacity: 0; visibility: hidden; transition:all 0.3s ease;}
.authentication-panel .form-content .form-action .btn-action{font-size: 16px; height: 56px;padding: 0 20px;width: 100%;max-width: 200px;border-radius: 4px;color: var(--shades01);background-color: var(--color01);}
.authentication-panel .form-content .form-options{display: flex;align-items: center;}
.authentication-panel .form-content .form-options .option-link{font-size: 16px;color: var(--shades01); background-color: transparent; padding: 0;}
.authentication-panel.active .panel-backdrop{opacity: 0.75;visibility: visible;}
.authentication-panel.active .panel-content{transform: translateX(0%);}
.authentication-panel .form-content.active .form-action{opacity:1; visibility: visible; pointer-events: auto;}
.authentication-panel .form-content.active{width: 100%;max-width: calc(100% - 20px);}

#authentication-form.active .form-action{opacity: 1; visibility: visible;}
.authentication-panel.active .forgot-panel{transform: translateX(100%);}
.authentication-panel.active .forgot-panel.active{transform: translateX(0%);}

.registration-panel{position: fixed;padding: 100px 0;width: 100%;height: 100%;overflow: auto;background-color: var(--shades07);z-index: 10;transform: translateX(100%);transition: all 0.3s ease;}
.registration-panel.active{transform: translateX(0%);}
.registration-panel .registration-image{position: absolute;width: 100%;height: 100%;bottom: 0;right: 0;z-index: -1;opacity: 0.02;}
.registration-panel .registration-image img{width:100%;height: 100%;object-fit: contain;object-position: bottom right;}
.registration-panel .panel-close{border: 0;padding: 2px 0 0;margin: 0;width: 40px;height: 40px;display: flex;justify-content: center;align-items: center;font-size: 16px;border-radius: 50%;background-color: var(--shades05);position: absolute;top: 30px;right: 30px;z-index: 3;color: var(--shades01);}
.registration-content{position: relative;width: 100%;max-width: 460px;}
.registration-content .registration-title{font-size: 56px;color: var(--shades01);line-height: 1;margin: 0 0 40px;font-weight: 300;}
.registration-content .registration-text{font-size: 20px;line-height: 1.2;color: var(--shades02);font-weight: 300;}
.registration-form{position: relative;}
.registration-form .form-group{margin: 0 0 50px;}
.registration-form .input-style{position: relative;z-index: 0;}
.registration-form .float-label{position: absolute;z-index: -1;top: 50%;left: 0;transform: translateY(-50%);font-size: 16px;color: var(--shades02); transition: all 0.3s ease;font-weight: 300;}
.registration-form .form-control{z-index: 1; font-size: 24px; font-weight: 300;height: 40px;line-height: 1;padding: 0;}
.registration-form .form-control:focus{border-color: var(--shades01);color: var(--shades01);}
.registration-form .form-control:focus ~ .float-label{font-size: 13px;margin: -24px 0 0;}
.registration-form .input-style.hasValue .float-label{font-size: 13px;margin: -24px 0 0;}
.registration-form .form-action{margin: 40px 0 0;display: flex;align-items: center;opacity: 0; visibility: hidden; transition:all 0.3s ease;}
.registration-form .form-action .btn-action{font-size: 16px;height: 56px;padding: 0 20px;width: 100%;max-width: 200px;border-radius: 4px;color: var(--shades01);background-color: var(--color01);}
.registration-form .form-options{display: flex;align-items: center;margin: 50px 0;}
.registration-form .form-options .form-check{position: relative;padding: 0;}
.registration-form .form-options .form-check .form-check-input{position: absolute;top: 50%;left: 0;transform: translateY(-50%);width: 20px;height: 20px;margin: 0;cursor: pointer;opacity: 0;z-index: 1;}
.registration-form .form-options .form-check .form-check-label{padding: 0 0 0 35px;position: relative;font-size: 16px;color: var(--shades02);}
.registration-form .form-options .form-check .form-check-label a{color: var(--shades01);}
.registration-form .form-options .form-check .form-check-label:before{content:''; position: absolute;top: 50%;left: 0;transform: translateY(-50%); width: 20px; height: 20px; background-color: var(--shades06); border: 1px solid var(--shades03);border-radius: 4px; transition: all 0.3s ease;}
.registration-form .form-options .form-check .form-check-label:after{content:'\f00c'; font-family: 'Font Awesome 5 Pro'; position: absolute;top: 50%;left: 0;transform: translateY(-50%);width: 20px; height: 20px;display: flex;justify-content: center;align-items: center;padding: 1px 0 0;font-weight: 400;font-size: 14px;color: var(--shades01);opacity: 0;transition: all 0.3s ease;}
.registration-form .form-options .form-check .form-check-input:checked ~ .form-check-label:before{background-color: var(--color01); border-color: var(--color01);}
.registration-form .form-options .form-check .form-check-input:checked ~ .form-check-label:after{opacity: 1;}
#registration-form.active .form-action{opacity: 1; visibility: visible;}

.registration-success-content{position: relative;width: 100%;max-width: 420px;}
.registration-success-content .registration-success-icon{width: 48px;height: 48px;margin: 0 0 40px;display: flex;justify-content: center;align-items: center;background-color: var(--color01);color: var(--shades01);border-radius: 50%;font-size: 24px;}
.registration-success-content .registration-success-title{font-size: 56px;color: var(--shades01);line-height: 1;margin: 0 0 40px;font-weight: 300;}
.registration-success-content .registration-success-text{font-size: 20px;line-height: 1.2;color: var(--shades02);font-weight: 300;}
.registration-success-content .registration-success-action{display: flex; align-items: center; margin: 100px 0 0;}
.registration-success-content .registration-success-action .btn-action{font-size: 18px;height: 50px;padding: 0 20px;display: flex;justify-content: center; align-items: center; border-radius: 4px;color: var(--shades01);background-color: var(--shades06); font-weight: 400;}
.registration-success-content .registration-success-action .btn-action:hover{background-color: var(--color01);}

/* ********|| HEADER ENDS ||******** */

/* ********|| BANNER SLIDER START ||******** */

/* ********|| BANNER SLIDER ENDS ||******** */

/* ********|| CONTENT START ||******** */
.page-filter{position: relative; display: flex; align-items: center;}
.page-filter .filter{position: relative;overflow: hidden;border-radius: 8px;}
.page-filter .filter+.filter{margin: 0 0 0 8px;}
.page-filter .filter .filter-radio{position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1; opacity: 0; cursor: pointer;}
.page-filter .filter .filter-label{position: relative;padding: 0 16px;padding-left: 24px;height: 52px;border-radius: 8px;background-color: transparent;display: flex;align-items: center;transition: all 0.3s ease; font-size: 14px; font-weight: 300; color: var(--shades02);}
.page-filter .filter .filter-label:before{content: ''; position: absolute;width: 8px;height: 8px;background-color: var(--color01);border-radius: 50%;top: 50%;transform: translateY(-50%);left: 10px;opacity: 0;transition: all 0.3s ease;}
.page-filter .filter .filter-radio:checked ~ .filter-label{background-color: var(--shades06); color: var(--shades01);}
.page-filter .filter .filter-radio:checked ~ .filter-label:before{opacity: 1;}

.gl-switch{display: flex; align-items: center;}
.gl-switch .btn-switch{background-color: transparent;color: var(--shades02);font-size: 14px;font-weight: 300;padding: 0;display: flex;justify-content: center;align-items: center;height: 40px;}
.gl-switch .btn-switch i{font-size: 18px;}
.gl-switch .btn-switch.active{color: var(--shades01);}
.gl-switch .btn-switch+.btn-switch{ margin-left: 20px;}

.sec-cta{position: relative; padding: 160px 0;border-top: 1px solid var(--color06); z-index: 0;}
.sec-cta .cta-image{position: absolute;width: 100%;height: 100%;top: 0;left: 0;z-index: -1;opacity: 0.02;}
.sec-cta .cta-image img{width:100%;height: 100%;object-fit: cover;object-position: center top;}
.sec-cta .cta-content{position: relative;}
.sec-cta .cta-content .cta-title{font-size: 56px;line-height: 1.2;color: var(--shades01);text-align: center;font-weight: 300;text-transform: uppercase;}
.sec-cta .cta-content .cta-actions{display: flex; align-items: center; justify-content: center; margin: 50px 0 0;}
.sec-cta .cta-content .cta-actions .btn-action{display:flex; justify-content: center; align-items: center;font-size: 18px;height: 60px;padding: 0 30px;border-radius: 8px;color: var(--shades01);background-color: var(--color01); font-weight: 400;}

/* ********|| CONTENT ENDS ||******** */

/* ********|| FOOTER STARTS ||******** */
footer{position: relative; height: 80px; display: flex; align-items: center; background-color: var(--shades08);border-top: 1px solid var(--shades06);}
footer .footer-copyright{font-size: 13px; font-weight: 300; color: var(--shades02);}
footer .footer-link{display: flex; align-items: center; justify-content: flex-end;}
footer .footer-link .item+.item{ margin-left: 16px;}
footer .footer-link .item .link{font-size: 13px; font-weight: 300; color: var(--shades02);}
footer .footer-link .item .link:hover{color: var(--shades02);}
/* ********|| FOOTER ENDS ||******** */

/* ********|| OTHER PAGES STARTS ||******** */

/* ********|| OTHER PAGES ENDS ||******** */

/* ********|| MODAL STARTS ||******** */

/* ********|| MODAL ENDS ||******** */

/*
font-family: 'Gantari', sans-serif;
*/

/* ********|| RESPONSIVE STARTS ||******** */

@media screen and (max-width: 1600px) {
 
}
@media screen and (max-width: 1540px) {
 
}
@media screen and (max-width: 1440px) {
 
}
@media screen and (max-width: 1400px) {
	
}
@media screen and (max-width: 1366px) {
	
}
@media screen and (max-width: 1280px) {
 
}
@media screen and (max-width: 1024px) {
	
}
@media screen and (max-width: 990px) {
	
}
@media screen and (max-width: 990px) and (orientation: landscape){
	
}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {
	
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

}
/* ********|| RESPONSIVE ENDS ||******** */