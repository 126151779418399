.sec-podcast-banner{position: relative; background-color: var(--shades08);}
.sec-podcast-banner .swiper{height:calc(100vh - 96px);}
.sec-podcast-banner .podcast{position: relative;width: 100%;height:calc(100vh - 96px);display: flex;align-items: center; pointer-events: auto;}
.sec-podcast-banner .podcast .podcast-image{width: 350px;height: 350px;overflow: hidden; position: relative; margin: 0 auto 60px;}
.sec-podcast-banner .podcast .podcast-image:before{content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: var(--color02); opacity: 0.6;}
.sec-podcast-banner .podcast .podcast-image .podcast-image-content{position: absolute;display: flex;justify-content: space-between;align-items: flex-end;width: 100%;padding: 30px;bottom: 0;left: 0;}
.sec-podcast-banner .podcast .podcast-image .podcast-image-title{font-size: 40px;line-height: 0.8;width: 100%;max-width: 150px;display: flex;justify-content: flex-end;align-items: center;color: var(--shades01);font-weight: 800;}
.sec-podcast-banner .podcast .podcast-image .podcast-image-number{font-size: 40px;line-height: 1;width: 100%;max-width: 60px;display: flex;justify-content: flex-end;align-items: center;color: var(--shades01);font-weight: 800;}
.sec-podcast-banner .podcast .podcast-image .podcast-image-container{width: 100%; height: 100%; overflow: hidden;}
.sec-podcast-banner .podcast .podcast-image .podcast-image-container img{width: 100%; height: 100%; object-fit: cover;}
.sec-podcast-banner .podcast .podcast-content{width: 100%;max-width: 760px;padding: 0 0 120px; margin: 0 auto; text-align: center;}
.sec-podcast-banner .podcast .podcast-title{margin: 20px 0;font-size: 32px;color: var(--shades01);line-height: 1.2;font-weight: 300;}
.sec-podcast-banner .podcast .podcast-time{font-size: 16px;color: var(--shades02);line-height: 1.2; font-weight: 300;}
.sec-podcast-banner .swiper-pagination{bottom: 60px; display: flex; position: relative;justify-content: center;}
.sec-podcast-banner .swiper-pagination .swiper-pagination-bullet{width: 40px;height: 6px;border-radius: 10px;margin: 0;background-color: var(--shades01);}
.sec-podcast-banner .swiper-pagination .swiper-pagination-bullet+.swiper-pagination-bullet{margin-left: 6px;}

.sec-podcast{position: relative; padding: 60px 0;}
.sec-podcast .podcast-action{display: flex;justify-content: center;align-items: center; margin: 40px 0 0;}
.sec-podcast .podcast-action .btn-action{color: var(--shades01); font-size: 16px;padding: 0 20px; background-color: transparent; border: 0;border-radius: 4px;line-height: 1;display: flex;justify-content: center;align-items: center;height: 52px;}
.sec-podcast .podcast-action .btn-action:hover{background-color: var(--shades06);}

.podcast-layout{position: relative; padding: 40px 0;}
.podcast-layout+.podcast-layout{margin: 40px 0 0;}
.podcast-layout-header{display: flex;justify-content: space-between;align-items: center;margin: 0px 0 30px;}
.podcast-layout-header .podcast-layout-title{margin: 0;font-size: 24px;color: var(--shades01);line-height: 1;margin-right: auto; font-weight: 400;} 
.podcast-layout-header .podcast-layout-title span{color: var(--shades02);}
.podcast-layout-header .btn-viewAll{font-size: 14px;color: var(--shades01);margin-left: auto;font-weight: 400;background-color: transparent;display: flex;align-items: center;justify-content: center;padding: 0 16px;border-radius: 4px;height: 40px;}
.podcast-layout-header .btn-viewAll:hover{background-color: var(--shades06);}

.podcast-layout-list{display: flex;position:relative;padding: 0;overflow: auto;}
.podcast-layout-list::-webkit-scrollbar{width: 0;height: 0;background-color:transparent;}
.podcast-layout-list::-webkit-scrollbar-thumb{background-color:transparent;}
.podcast-layout-list::-webkit-scrollbar-track{background-color:transparent;}
.podcast-layout-list .swiper{padding: 0 var(--padding);}
.podcast-layout-list .podcast-layout-item{display: block; color: var(--shades01);}
.podcast-layout-list .podcast-layout-item .podcast-layout-inner{position: relative;}
.podcast-layout-list .podcast-layout-item .podcast-status{position: absolute;top: 10px;right: 10px;padding: 6px 10px;border-radius: 4px;font-size: 16px;color: var(--shades01);z-index: 1;overflow: hidden;}
.podcast-layout-list .podcast-layout-item .podcast-status:before{ content:''; position: absolute;top: 0;right: 0; width: 100%; height: 100%; background-color: var(--shades08); opacity: 0.6;z-index: -1;}
.podcast-layout-list .podcast-layout-item .podcast-layout-thumbnail{width: 100%; height: 240px; overflow: hidden; border-radius: 4px;position: relative;}
.podcast-layout-list .podcast-layout-item .podcast-layout-thumbnail img{width: 100%; height:100%; object-fit: cover;}
.podcast-layout-list .podcast-layout-item .podcast-layout-content{padding: 20px 0;}
.podcast-layout-list .podcast-layout-item .podcast-layout-content .podcast-title{ font-size: 16px; font-weight: 300; color: var(--shades01);}
.podcast-layout-list .podcast-layout-item .podcast-layout-content .podcast-text{font-size: 14px; font-weight: 300; color: var(--shades02);}

.podcast-layout-list.podcast-alt .podcast-layout-item .podcast-layout-thumbnail{height: 500px;}
.podcast-layout-list.podcast-alt .podcast-layout-item .podcast-image{width: 100%;height: 100%;overflow: hidden; position: relative; margin: 0 auto 60px;}
.podcast-layout-list.podcast-alt .podcast-layout-item .podcast-image:before{content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: var(--color02); opacity: 0.6;}
.podcast-layout-list.podcast-alt .podcast-layout-item .podcast-image .podcast-image-content{position: absolute;display: flex;justify-content: space-between;align-items: flex-end;width: 100%;padding: 30px;bottom: 0;left: 0;}
.podcast-layout-list.podcast-alt .podcast-layout-item .podcast-image .podcast-image-title{font-size: 50px;line-height: 0.8;width: 100%;max-width: 200px;display: flex;justify-content: flex-end;align-items: center;color: var(--shades01);font-weight: 800;}
.podcast-layout-list.podcast-alt .podcast-layout-item  .podcast-image .podcast-image-number{font-size: 50px;line-height: 1;width: 100%;max-width: 80px;display: flex;justify-content: flex-end;align-items: center;color: var(--shades01);font-weight: 800;}
.podcast-layout-list.podcast-alt .podcast-layout-item .podcast-image .podcast-image-container{width: 100%; height: 100%; overflow: hidden;}
.podcast-layout-list.podcast-alt .podcast-layout-item .podcast-image .podcast-image-container img{width: 100%; height: 100%; object-fit: cover;}
.podcast-layout-list.podcast-alt .podcast-layout-item .podcast-layout-thumbnail .podcast-live{position: absolute;bottom: 20px;left: 20px;background-color: var(--colorLive);padding: 0 8px;padding-left: 20px;height: 24px;line-height: 1;display: flex;justify-content: center;align-items: center;border-radius: 30px;font-size: 12px;font-weight: 500;text-transform: uppercase;}
.podcast-layout-list.podcast-alt .podcast-layout-item .podcast-layout-thumbnail .podcast-live:before{content: ''; position: absolute;width: 8px;height: 8px;background-color: var(--shades01);border-radius: 50%;top: 50%;transform: translateY(-50%);left: 8px;}