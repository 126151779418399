.sec-stories-banner{position: relative;background-color: var(--shades07);}
.sec-stories-banner .swiper{height:calc(100vh - 96px);}
.sec-stories-banner .stories{position: relative;width: 100%;height:calc(100vh - 96px);display: flex;align-items: flex-end; pointer-events: auto;}
.sec-stories-banner .stories .stories-image{position: absolute; top: 0; left: 0; z-index: -1; width: 100%; height: 100%; overflow: hidden;}
.sec-stories-banner .stories .stories-image:before{content: '';position: absolute;width: 100%;height: 100%;background: linear-gradient(180deg, rgba(18, 18, 18, 0),
  #121212 84.38%);opacity: 0.7;z-index: 1}
.sec-stories-banner .stories .stories-image img{width: 100%; height: 100%; object-fit: cover;}
.sec-stories-banner .stories .container{display:flex;}
.sec-stories-banner .stories .stories-content{width: 100%;max-width: 700px;padding: 0 0 160px;margin: 0;text-align: left;}
.sec-stories-banner .stories .stories-action{display: flex; align-items: center;margin: 0 0 30px;}
.sec-stories-banner .stories .btn-action{margin: 0;padding: 0;width: 80px;height: 80px;display: flex;align-items: center;justify-content: center;border-radius: 50%;font-size: 18px;border: 0;color: var(--shades01); background-color: transparent;position: relative; overflow: hidden;}
.sec-stories-banner .stories .btn-action:before{content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: var(--shades01); opacity: 0.15;}
.sec-stories-banner .stories .stories-title{margin: 20px 0;font-size: 48px;color: var(--shades01);line-height: 1.2;font-weight: 300;}
.sec-stories-banner .stories .stories-time{font-size: 16px;color: var(--shades02);line-height: 1.2; font-weight: 300;}
.sec-stories-banner .swiper-pagination{bottom: 60px; display: flex; position: relative; margin: 0 auto; width: 100%; padding: 0;}
.sec-stories-banner .swiper-pagination .swiper-pagination-bullet{width: 40px;height: 6px;border-radius: 10px;margin: 0;background-color: var(--shades01);}
.sec-stories-banner .swiper-pagination .swiper-pagination-bullet+.swiper-pagination-bullet{margin-left: 6px;}

.sec-stories{position: relative; padding: 60px 0;}
.sec-stories .stories-action{display: flex;justify-content: center;align-items: center; margin: 100px 0;}
.sec-stories .stories-action .btn-action{color: var(--shades01); font-size: 16px;padding: 0 20px; background-color: transparent; border: 0;border-radius: 4px;line-height: 1;display: flex;justify-content: center;align-items: center;height: 52px;}
.sec-stories .stories-action .btn-action:hover{background-color: var(--shades06);}

.stories-grid{position: relative; padding: 100px 0;}
.stories-grid .stories-layout{position: relative;}
.stories-grid .stories-layout .container{position: relative;}
.stories-grid .stories-layout .stories-layout-inner{position: relative; display: flex; align-items: center; margin: 0 -15px;}
.stories-grid .stories-layout+.stories-layout{margin-top: 160px;}
.stories-grid .stories-layout .stories-image{position: relative;width: 100%; max-width: calc(45% - 30px); margin: 0 15px; height:400px;}
.stories-grid .stories-layout .stories-image:before{content: ''; position: absolute; top: 0; right: 0; width: 100%; height: 100%; background: linear-gradient(to right, transparent, var(--shades08)); opacity: 0.5;}
.stories-grid .stories-layout .stories-image img{width: 100%; height: 100%; object-fit: cover;}
.stories-grid .stories-layout .stories-content{position: relative;width: 100%; max-width: calc(45% - 30px); margin: 0 15px;}
.stories-grid .stories-layout .stories-title{font-size: 24px; font-weight:300; line-height: 1.2;margin: 0;max-width: 400px;color: var(--shades01);transform: translateX(-150px);}
.stories-grid .stories-layout .stories-text{font-size: 16px;color: var(--shades02);margin: 10px 0 0;}

.stories-grid .stories-layout.stories-layout-2{margin-top: -60px;}
.stories-grid .stories-layout.stories-layout-2 .stories-content{margin-left: auto;}
.stories-grid .stories-layout.stories-layout-2 .stories-image{max-width: 100%;margin: 0 0 20px;}
.stories-grid .stories-layout.stories-layout-2 .stories-title{transform: none;}

.stories-grid .stories-layout.stories-layout-3 .stories-content{margin:0 auto 0 0;max-width: 75%;}
.stories-grid .stories-layout.stories-layout-3 .stories-image{max-width: 100%;margin: 0 0 20px;height: 700px;}
.stories-grid .stories-layout.stories-layout-3 .stories-title{position: absolute;top: 50%;left: auto; right:0;transform: translateX(200px);}

.stories-grid .stories-layout.stories-layout-4 .stories-layout-inner{margin:0 auto;}
.stories-grid .stories-layout.stories-layout-4 .stories-content{margin: 0 auto;max-width: 100%;position: absolute;left: 0;bottom: 0;padding: 100px 60px;}
.stories-grid .stories-layout.stories-layout-4 .stories-image{max-width: 100%;margin:0;height: auto;}
.stories-grid .stories-layout.stories-layout-4 .stories-image:before{content: ''; position: absolute; top: 0; right: 0; width: 100%; height: 100%; background: linear-gradient(to bottom, transparent, var(--shades08)); opacity: 0.5;}
.stories-grid .stories-layout.stories-layout-4 .stories-title{transform: none;font-size: 32px;max-width: 560px;font-weight: 300;}
.stories-grid .stories-layout.stories-layout-4 .stories-text{color: var(--shades01);}

.stories-slider .swiper{padding: 0 var(--padding);}
.stories-slider .stories-content{display: block; color: var(--shades01);}
.stories-slider .stories-image{width: 100%; height: 200px; overflow: hidden;margin: 0 0 20px;border-radius: 4px;}
.stories-slider .stories-image img{width: 100%; height: 100%; object-fit: cover;}
.stories-slider .stories-title{font-size: 14px;line-height: 1.2;color: var(--shades01); font-weight: 300;}

.sec-stories-list{position: relative; padding: 100px 0;}
.sec-stories-list .stories-list{position: relative;}
.sec-stories-list .stories-list .stories-item{display: flex; color: var(--shades01);}
.sec-stories-list .stories-list .stories-item+.stories-item{margin: 40px 0 0;}
.sec-stories-list .stories-list .stories-item .item-image{width: 560px; height: 320px; overflow: hidden;}
.sec-stories-list .stories-list .stories-item .item-image img{width: 100%; height: 100%; object-fit: cover;}
.sec-stories-list .stories-list .stories-item .item-content{width: 100%; max-width: calc(100% - 560px); padding-left: 60px;}
.sec-stories-list .stories-list .stories-item .item-title{font-size: 32px; font-weight: 300; max-width: 560px;}