.sec-search{position: relative; padding: 100px 0; min-height: calc(100vh - 96px); background-color: var(--shades08);}
.sec-search .form-content{width: 100%;max-width: calc(100% - 20px);}
.sec-search .form-content .form-group{margin: 0 0 20px;}
.sec-search .form-content .input-style{position: relative;z-index: 0;}
.sec-search .form-content .float-label{position: absolute;z-index: -1;top: 50%;left: 0;transform: translateY(-50%);font-size: 16px;color: var(--shades01); transition: all 0.3s ease;}
.sec-search .form-content .form-control{z-index: 1;}
.sec-search .form-content .form-control:focus{border-color: var(--shades01);color: var(--shades01);}
.sec-search .form-content .form-control:focus ~ .float-label{font-size: 13px;margin: -24px 0 0; color: var(--shades03);}
.sec-search .form-content .input-style.hasValue .float-label{font-size: 13px;margin: -24px 0 0; color: var(--shades03);}
.sec-search .form-content .input-style .input-reset{position: absolute;bottom: 0;right: 0;width: 40px;height: 40px;padding: 0;background-color: transparent;font-size: 20px;color: var(--shades01); opacity: 0; visibility: hidden; transition: all 0.3s ease;}
.sec-search .form-content .input-style.hasValue .input-reset{opacity: 1; visibility: visible;}
.sec-search .search-action{ display: none; align-items: center; margin: 30px 0 0;}
.sec-search .search-action.active{ display: flex;}
.sec-search .search-action .btn-action{padding: 0 16px;line-height: 1.5;font-size: 15px; font-weight: 400; color: var(--shades01); background-color: var(--color01); height: 52px; display: flex; justify-content: center; align-items: center; border: 0; border-radius: 4px;}

.search-suggestion{position: relative;display: none;}
.search-suggestion.active{display: block;}
.search-suggestion .suggestion-list{position: relative;padding: 32px 16px;background-color: var(--shades07);border-radius: 0 0 6px 6px;}
.search-suggestion .suggestion-list .suggestion-item{display: flex;align-items: center;padding: 16px;width: 100%;text-align: left;background-color: transparent; border-radius: 6px;}
.search-suggestion .suggestion-list .suggestion-item .item-icon{width: 36px; height: 36px; background-color: var(--shades05); border-radius: 50%; display: flex; justify-content: center; align-items: center; color: var(--shades01); font-size: 14px; transition: all 0.3s ease;}
.search-suggestion .suggestion-list .suggestion-item .item-content{width: 100%;max-width: calc(100% - 36px);padding: 0 16px;}
.search-suggestion .suggestion-list .suggestion-item .item-title{font-size: 20px;font-weight: 300;color: var(--shades02);white-space: nowrap;overflow: hidden;text-overflow: ellipsis; transition: all 0.3s ease;}
.search-suggestion .suggestion-list .suggestion-item .item-text{font-size: 11px;font-weight: 500;color: var(--shades03);}
.search-suggestion .suggestion-list .suggestion-item:hover{background-color: var(--shades06);}
.search-suggestion .suggestion-list .suggestion-item:hover .item-icon{ background-color: var(--shades01); color: var(--shades07);}
.search-suggestion .suggestion-list .suggestion-item:hover .item-title{ color: var(--shades01);}
.search-suggestion .suggestion-list .suggestion-item:hover .item-text{ color: var(--shades02);}
