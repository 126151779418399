.sec-stories-details-banner{position: relative;background-color: var(--shades07);height: calc(100vh - 96px); z-index: 0; display: flex; align-items: flex-end;}
.sec-stories-details-banner .banner-image{position: absolute;top: 0;left: 0;z-index: -1;width: 100%;height: 100%;overflow: hidden;}
.sec-stories-details-banner .banner-image::before{content: '';position: absolute;width: 100%;height: 100%;background: linear-gradient(180deg, rgba(18, 18, 18, 0), #121212 84.38%);opacity: 0.7;z-index: 1;}
.sec-stories-details-banner .banner-image img{width: 100%;height: 100%;object-fit: cover;}
.sec-stories-details-banner .banner-content{width: 100%; max-width: 700px; padding: 0 0 100px;}
.sec-stories-details-banner .banner-title{margin: 20px 0;font-size: 48px;color: var(--shades01);line-height: 1.2;font-weight: 300;}
.sec-stories-details-banner .banner-action{display: flex; align-items: center; margin: 40px 0 0;}
.sec-stories-details-banner .banner-action .btn-action{padding: 0 16px; line-height: 1.5; font-size: 16px; font-weight: 400; color: var(--shades08); background-color: var(--shades01); height: 52px; display: flex; justify-content: center; align-items: center; border: 0; border-radius: 4px; border:1px solid rgba(255,255,255,0.2);width:200px;}
.sec-stories-details-banner .banner-action .btn-action.alt{padding: 0 16px; line-height: 1.5; font-size: 16px; font-weight: 400; color: var(--shades01); background-color:transparent; height: 52px; display: flex; justify-content: center; align-items: center; border: 0; border-radius: 4px; border:1px solid rgba(255,255,255,0.2);width:200px;}

.sec-stories-details{position: relative; padding: 120px 0;}
.sec-stories-details .stories-audio-container{position: relative;padding: 40px;background-color: var(--shades07);border: 1px solid var(--shades06);border-radius: 8px;margin: 0 0 40px;display: flex;align-items: center;height: 100px;justify-content: center; font-size: 20px; font-weight: 300; color: var(--shades02); text-transform: uppercase;}
.sec-stories-details .stories-details-content{position: relative; margin: 0 0 40px;}
.sec-stories-details .stories-details-content .stories-details-title{font-size: 32px; font-weight: 300; width: 100%; max-width: 560px; line-height: 1.4;}
.sec-stories-details .stories-details-content .stories-details-text{font-weight: 300;font-size: 20px;line-height: 1.4; color: var(--shades02);}
.sec-stories-details .stories-details-content .stories-details-image{width: 100%; position: relative; margin: 100px 0;}
.sec-stories-details .stories-details-content .stories-details-image img{width: 100%;}
.sec-stories-details .stories-details-content .stories-details-image figcaption{font-size: 14px;font-weight: 300;color: var(--shades02);margin: 10px 0;}
.sec-stories-details .stories-share{margin: 100px 0 0;}
.sec-stories-details .stories-share .share-title{font-weight: 300; font-size: 16px;margin: 0 0 5px; color: var(--shades02);}
.sec-stories-details .stories-share .share-list{display: flex; margin: 0 -5px;}
.sec-stories-details .stories-share .share-list .share-item{ display: flex; justify-content: center; align-items: center; border-radius: 50%; width: 48px; height: 48px; background-color: var(--shades06); color: var(--shades02); font-size: 16px;margin: 0 5px;}
.sec-stories-details .stories-share .share-list .share-item:hover{color: var(--shades01);background-color: var(--color01);}


