.section-podcast-play{position: relative; padding: 40px 0 100px; background-color: var(--shades08);}
.section-podcast-play .podcast-content{ position: relative; width: 100%;}
.section-podcast-play .podcast-title{font-weight: 300;font-size: 48px;}
.section-podcast-play .podcast-player{position: relative; width: 100%; margin: 80px 0; background-color: var(--shades07); padding: 20px; display: flex; align-items: center; height: 160px;}
.section-podcast-play .podcast-options{display: flex; flex-wrap: wrap; }
.section-podcast-play .podcast-share{position: relative;}
.section-podcast-play .podcast-share .share-title{font-weight: 300; font-size: 16px;margin: 0 0 5px; color: var(--shades02);}
.section-podcast-play .podcast-share .share-list{display: flex; margin: 0 -5px;}
.section-podcast-play .podcast-share .share-list .share-item{ display: flex; justify-content: center; align-items: center; border-radius: 50%; width: 48px; height: 48px; background-color: var(--shades06); color: var(--shades02); font-size: 16px;margin: 0 5px;}
.section-podcast-play .podcast-share .share-list .share-item:hover{color: var(--shades01);background-color: var(--color01);}

.section-podcast-play .podcast-service{position: relative; margin-left: 100px;}
.section-podcast-play .podcast-service .service-title{font-weight: 300; font-size: 16px;margin: 0 0 5px; color: var(--shades02);}
.section-podcast-play .podcast-service .service-list{display: flex; margin: 0 -10px;}
.section-podcast-play .podcast-service .service-list .service-item{ display: flex; justify-content: center; align-items: center; border-radius: 50%; width: 48px; height: 48px; color: var(--shades02); font-size: 16px;margin: 0 10px; padding: 4px;}
.section-podcast-play .podcast-service .service-list .service-item img{width: 100%;}

.sec-podcast{position: relative; padding: 60px 0;}
.sec-podcast .podcast-action{display: flex;justify-content: center;align-items: center; margin: 40px 0 0;}
.sec-podcast .podcast-action .btn-action{color: var(--shades01); font-size: 16px;padding: 0 20px; background-color: transparent; border: 0;border-radius: 4px;line-height: 1;display: flex;justify-content: center;align-items: center;height: 52px;}
.sec-podcast .podcast-action .btn-action:hover{background-color: var(--shades06);}

.sec-podcast-details{position: relative; padding: 120px 0;}
.sec-podcast-details .podcast-details-content{position: relative; margin: 0 0 40px;}
.sec-podcast-details .podcast-details-content .podcast-details-title{font-size: 32px; font-weight: 300; width: 100%; max-width: 560px; line-height: 1.4;}
.sec-podcast-details .podcast-details-content .podcast-details-text{font-weight: 300;font-size: 20px;line-height: 1.4; color: var(--shades02);}
.sec-podcast-details .podcast-details-content .podcast-details-image{width: 100%; position: relative; margin: 100px 0;}
.sec-podcast-details .podcast-details-content .podcast-details-image img{width: 100%;}
.sec-podcast-details .podcast-details-content .podcast-details-image figcaption{font-size: 14px;font-weight: 300;color: var(--shades02);margin: 10px 0;}