header .navigation{display: none;}
.sec-membership-banner{position: relative; padding: 60px 0; background-color: var(--shades08);}
.sec-membership-banner .banner-content{position: relative;}
.sec-membership-banner .banner-content .banner-subtitle{font-size: 16px;font-weight: 300;color: var(--shades01);}
.sec-membership-banner .banner-content .banner-title{font-size: 360px;text-transform: uppercase;letter-spacing: -12px;font-weight: 600;display: flex;flex-direction: column;line-height: 0.8;color: var(--shades01);margin-left: -24px;}

.sec-membership-members{position: relative;padding: 300px 0;background-color: var(--shades08);}
.sec-membership-members .membership-members{position: relative;display: flex; justify-content: space-between; flex-wrap: wrap;margin: 0 -15px;}
.sec-membership-members .membership-members .member{width: 100%;max-width: 180px;margin: 0 15px;position: relative;display: flex;}
.sec-membership-members .membership-members .member .member-image{width: 100%;overflow: hidden;border-radius: 100px;height: 280px;position: relative; opacity: 0.8;}
.sec-membership-members .membership-members .member .member-image img{width: 100%; height: 100%; object-fit: cover;}
.sec-membership-members .membership-members .member:nth-child(odd) .member-image{transform: translateY(-60%);}
.sec-membership-members .membership-members .member:nth-child(even) .member-image{transform: translateY(60%);}

.sec-membership-features{ position: relative; padding: 200px 0 240px; background-color: var(--shades08);}
.sec-membership-features .features-content{position: relative;}
.sec-membership-features .features-content .features-title{font-weight: 300;font-size: 96px;line-height: 1;margin: 0;letter-spacing: -4px;color: var(--shades01);}
.features-list{position: relative;}
.features-list .feature-item{display: flex;}
.features-list .feature-item+.feature-item{margin: 40px 0 0;}
.features-list .feature-item .item-icon{width: 64px;height: 64px;display: flex;justify-content: center;align-items: center;position: relative;font-size: 18px;font-weight: 600;color: var(--shades01);}
.features-list .feature-item .item-icon span{position: relative; z-index: 1;}
.features-list .feature-item .item-icon svg{width: 100%;height: 100%;position: absolute; top: 0; left: 0;}
.features-list .feature-item .item-icon svg circle{width: 100%;height: 100%;fill: var(--shades06);stroke-width: 6px;stroke: var(--shades06);transform: translate(4px, 4px);}
.features-list .feature-item .item-icon svg circle:nth-child(2){ stroke: var(--color01);stroke-dasharray: 176;stroke-dashoffset: 220;}
.features-list .feature-item .item-content{width: 100%; max-width: calc(100% - 100px); margin-left: 36px;}
.features-list .feature-item .item-content .item-title{font-weight: 400;font-size: 24px;color: var(--shades01); margin: 0;}
.features-list .feature-item .item-content .item-text{font-weight: 300; font-size: 20px;color: var(--shades02); margin: 20px 0 0;}

.sec-membership-details{position: relative;padding: 200px 0 340px;border-radius: 60px 60px 0 0;background-color: var(--shades07);margin-top: -40px; overflow: hidden;}
.sec-membership-details .container{position: relative;}
.sec-membership-details .membership-details-image{position: absolute;right: 0;top: 0px;width: 460px;}
.sec-membership-details .membership-details-image img{width: 100%;height: 100%;object-fit: contain;}
.sec-membership-details .membership-details-content{position: relative;padding: 40px 0;}
.sec-membership-details .membership-details-icon{width: 64px;height: 64px;background-color: var(--color01);display: flex;justify-content: center;align-items: center;border-radius: 50%;font-size: 20px;margin: 0 0 60px;}
.sec-membership-details .membership-details-title{font-size: 48px;font-weight: 300;color: var(--shades01);margin: 0;}
.sec-membership-details .membership-details-text{font-size: 20px;font-weight: 300;color: var(--shades02);margin: 20px 0 0;}
.sec-membership-details .membership-details-option{ display: flex; align-items: center; margin: 60px 0 0;}
.sec-membership-details .membership-details-option .btn-option{height: 40px;background-color: var(--shades05);font-size: 14px;font-weight: 400;color: var(--shades01);padding: 0 20px;border-radius: 30px; display: flex; justify-content: center; align-items: center;}
.sec-membership-details .membership-details-option .btn-option i{ font-size: 16px; color: var(--color03);}

.sec-membership-benefit{position: relative;padding: 300px 0;background-color: var(--shades08);}
.sec-membership-benefit .membership-benefit-content{}
.sec-membership-benefit .membership-benefit-content .membership-benefit-title{}
