.sec-search-result{position: relative; padding: 60px 0;}
.sec-search-result .btn-back{width: 48px;height: 48px;display: flex;justify-content: center;align-items: center;border-radius: 50%;background-color: var(--shades06);padding: 0;font-size: 20px;color: var(--shades01);}
.sec-search-result .btn-back:hover{background-color: var(--color01);}
.sec-search-result .search-query{font-size: 48px; font-weight: 300; color: var(--shades01); margin: 0 0 20px;}


.search-filter{position: relative; display: flex; align-items: center;}
.search-filter .filter{position: relative;overflow: hidden;border-radius: 8px;}
.search-filter .filter+.filter{margin: 0 0 0 8px;}
.search-filter .filter .filter-radio{position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1; opacity: 0; cursor: pointer;}
.search-filter .filter .filter-label{position: relative;padding: 0 16px;padding-left: 24px;height: 52px;border-radius: 8px;background-color: transparent;display: flex;align-items: center;transition: all 0.3s ease; font-size: 14px; font-weight: 300; color: var(--shades02);}
.search-filter .filter .filter-label:before{content: ''; position: absolute;width: 8px;height: 8px;background-color: var(--color01);border-radius: 50%;top: 50%;transform: translateY(-50%);left: 10px;opacity: 0;transition: all 0.3s ease;}
.search-filter .filter .filter-radio:checked ~ .filter-label{background-color: var(--shades06); color: var(--shades01);}
.search-filter .filter .filter-radio:checked ~ .filter-label:before{opacity: 1;}

.search-video{position: relative; padding: 40px 0;}
.search-video .search-video-header{display: flex;justify-content: space-between;align-items: center;margin: 0px 0 10px;}
.search-video .search-video-title{margin: 0;font-size: 24px;color: var(--shades01);line-height: 1;margin-right: auto;font-weight: 400;}
.search-video .btn-viewAll{font-size: 14px;color: var(--shades01);margin-left: auto;font-weight: 400;background-color: transparent;display: flex;align-items: center;justify-content: center;padding: 0 16px;border-radius: 4px;height: 40px;}
.search-video .btn-viewAll:hover{background-color: var(--shades06);}

.search-video-list{position: relative; margin:0 0 20px;}
.search-video-list .search-video-item{position: relative;}
.search-video-list .item-inner{position: relative; display: block;color: var(--shades01);}
.search-video-list .item-thumbnail{width: 100%;height: 160px;overflow: hidden;border-radius: 4px;position: relative;}
.search-video-list .item-thumbnail img{width: 100%;height: 100%;object-fit: cover;}
.search-video-list .item-content{padding: 20px 0;}
.search-video-list .item-content .item-title{font-size: 16px;font-weight: 300;color: var(--shades01);}
.search-video-list .item-content .item-text{font-size: 14px;font-weight: 300;color: var(--shades02);}

.search-podcast{position: relative; padding: 40px 0;}
.search-podcast .search-podcast-header{display: flex;justify-content: space-between;align-items: center;margin: 0px 0 10px;}
.search-podcast .search-podcast-title{margin: 0;font-size: 24px;color: var(--shades01);line-height: 1;margin-right: auto;font-weight: 400;}
.search-podcast .btn-viewAll{font-size: 14px;color: var(--shades01);margin-left: auto;font-weight: 400;background-color: transparent;display: flex;align-items: center;justify-content: center;padding: 0 16px;border-radius: 4px;height: 40px;}
.search-podcast .btn-viewAll:hover{background-color: var(--shades06);}

.search-podcast-list{position: relative; margin:0 0 20px;}
.search-podcast-list .search-podcast-item{padding:10px 0;}
.search-podcast-list .item-inner{display: flex; background-color: var(--shades06);padding: 10px; border-radius: 4px; color: var(--shades01);}
.search-podcast-list .item-thumbnail{width: 120px; height: 120px; overflow: hidden; border-radius: 4px;}
.search-podcast-list .item-thumbnail img{width: 100%;height: 100%;object-fit: cover;}
.search-podcast-list .item-content{width: 100%;max-width: calc(100% - 140px);margin-left: 20px; display: flex; flex-direction: column;}
.search-podcast-list .item-title{font-size: 20px;font-weight: 300;color: var(--shades01); margin: auto 0;}

.search-stories{position: relative; padding: 40px 0;}
.search-stories .search-stories-header{display: flex;justify-content: space-between;align-items: center;margin: 0px 0 10px;}
.search-stories .search-stories-title{margin: 0;font-size: 24px;color: var(--shades01);line-height: 1;margin-right: auto;font-weight: 400;}
.search-stories .btn-viewAll{font-size: 14px;color: var(--shades01);margin-left: auto;font-weight: 400;background-color: transparent;display: flex;align-items: center;justify-content: center;padding: 0 16px;border-radius: 4px;height: 40px;}
.search-stories .btn-viewAll:hover{background-color: var(--shades06);}

.search-stories-list{position: relative; margin:0 0 20px;}
.search-stories-list .search-stories-item{padding:10px 0;}
.search-stories-list .item-inner{display: flex; background-color: var(--shades06);padding: 10px; border-radius: 4px; color: var(--shades01);}
.search-stories-list .item-thumbnail{width: 280px; height: 150px; overflow: hidden; border-radius: 4px;}
.search-stories-list .item-thumbnail img{width: 100%;height: 100%;object-fit: cover;}
.search-stories-list .item-content{width: 100%;max-width: calc(100% - 300px);margin-left: 20px; display: flex; flex-direction: column; justify-content: center;}
.search-stories-list .item-title{font-size: 20px;font-weight: 300;color: var(--shades01);}
.search-stories-list .item-text{font-size: 14px;font-weight: 300;color: var(--shades02); margin: 10px 0 0;}