.sec-video-banner{position: relative;background-color: var(--shades08);}
.sec-video-banner .swiper{height:calc(100vh - 96px);}
.sec-video-banner .video{position: relative;width: 100%;height:calc(100vh - 96px);display: flex;align-items: flex-end; pointer-events: auto;}
.sec-video-banner .video .video-image{position: absolute; top: 0; left: 0; z-index: -1; width: 100%; height: 100%; overflow: hidden;}
.sec-video-banner .video .video-image:before{content: '';position: absolute;width: 100%;height: 100%;background: linear-gradient(180deg, rgba(18, 18, 18, 0),
  #121212 84.38%);opacity: 0.7;z-index: 1}
.sec-video-banner .video .video-image img{width: 100%; height: 100%; object-fit: cover;}
.sec-video-banner .video .container{display:flex;}
.sec-video-banner .video .video-content{width: 100%;max-width: 600px;padding: 0 0 160px;margin: 0 auto;text-align: center;}
.sec-video-banner .video .video-action{display: flex; align-items: center;margin: 0 0 30px;}
.sec-video-banner .video .btn-action{margin: 0;padding: 0;width: 80px;height: 80px;display: flex;align-items: center;justify-content: center;border-radius: 50%;font-size: 18px;border: 0;color: var(--shades01); background-color: transparent;position: relative; overflow: hidden;}
.sec-video-banner .video .btn-action:before{content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: var(--shades01); opacity: 0.15;}
.sec-video-banner .video .video-title{margin: 20px 0;font-size: 48px;color: var(--shades01);line-height: 1.2;font-weight: 400;}
.sec-video-banner .video .video-time{font-size: 16px;color: var(--shades02);line-height: 1.2; font-weight: 300;}
.sec-video-banner .swiper-pagination{bottom: 60px; display: flex; position: relative;justify-content: center;}
.sec-video-banner .swiper-pagination .swiper-pagination-bullet{width: 40px;height: 6px;border-radius: 10px;margin: 0;background-color: var(--shades01);}
.sec-video-banner .swiper-pagination .swiper-pagination-bullet+.swiper-pagination-bullet{margin-left: 6px;}

.sec-videos{position: relative; padding: 60px 0;}
.sec-videos .video-action{display: flex;justify-content: center;align-items: center; margin: 40px 0 0;}
.sec-videos .video-action .btn-action{color: var(--shades01); font-size: 16px;padding: 0 20px; background-color: transparent; border: 0;border-radius: 4px;line-height: 1;display: flex;justify-content: center;align-items: center;height: 52px;}
.sec-videos .video-action .btn-action:hover{background-color: var(--shades06);}

.video-layout{position: relative; padding: 40px 0;}
.video-layout+.video-layout{margin: 40px 0 0;}
.video-layout-header{display: flex;justify-content: space-between;align-items: center;margin: 0px 0 30px;}
.video-layout-header .video-layout-title{margin: 0;font-size: 24px;color: var(--shades01);line-height: 1;margin-right: auto; font-weight: 400;} 
.video-layout-header .video-layout-title span{color: var(--shades02);}
.video-layout-header .btn-viewAll{font-size: 14px;color: var(--shades01);margin-left: auto;font-weight: 400;background-color: transparent;display: flex;align-items: center;justify-content: center;padding: 0 16px;border-radius: 4px;height: 40px;}
.video-layout-header .btn-viewAll:hover{background-color: var(--shades06);}

.video-layout-list{display: flex;position:relative;padding: 0;overflow: auto;}
.video-layout-list::-webkit-scrollbar{width: 0;height: 0;background-color:transparent;}
.video-layout-list::-webkit-scrollbar-thumb{background-color:transparent;}
.video-layout-list::-webkit-scrollbar-track{background-color:transparent;}
.video-layout-list .swiper{padding: 0 var(--padding);}
.video-layout-list .video-layout-item{display: block; color: var(--shades01);}
.video-layout-list .video-layout-item .video-layout-inner{position: relative;}
.video-layout-list .video-layout-item .video-status{position: absolute;top: 10px;right: 10px;padding: 6px 10px;border-radius: 4px;font-size: 16px;color: var(--shades01);z-index: 1;overflow: hidden;}
.video-layout-list .video-layout-item .video-status:before{ content:''; position: absolute;top: 0;right: 0; width: 100%; height: 100%; background-color: var(--shades08); opacity: 0.6;z-index: -1;}
.video-layout-list .video-layout-item .video-layout-thumbnail{width: 100%; height: 180px; overflow: hidden; border-radius: 4px;position: relative;}
.video-layout-list .video-layout-item .video-layout-thumbnail img{ width: 100%; height: 100%; object-fit: cover;}
.video-layout-list .video-layout-item .video-layout-content{padding: 20px 0;}
.video-layout-list .video-layout-item .video-layout-content .video-title{ font-size: 16px; font-weight: 300; color: var(--shades01);}
.video-layout-list .video-layout-item .video-layout-content .video-text{font-size: 14px; font-weight: 300; color: var(--shades02);}
.video-layout-list.live .video-layout-item .video-layout-thumbnail{height: 400px;}
.video-layout-list.live .video-layout-item .video-layout-thumbnail .video-live{position: absolute;bottom: 20px;left: 20px;background-color: var(--colorLive);padding: 0 8px;padding-left: 20px;height: 24px;line-height: 1;display: flex;justify-content: center;align-items: center;border-radius: 30px;font-size: 12px;font-weight: 500;text-transform: uppercase;}
.video-layout-list.live .video-layout-item .video-layout-thumbnail .video-live:before{content: ''; position: absolute;width: 8px;height: 8px;background-color: var(--shades01);border-radius: 50%;top: 50%;transform: translateY(-50%);left: 8px;}

