.section-video-play{position: relative; padding: 40px 0 100px; background-color: var(--shades08);}
.section-video-play .container{display: flex; flex-wrap: wrap;}
.section-video-play .video-content{width: 100%;max-width: calc(30% - 30px);margin: 0 15px; display: flex; flex-direction: column;}
.section-video-play .video-title{font-weight: 300;font-size: 48px;}
.section-video-play .video-text{font-weight: 300;font-size: 16px;margin: 40px 0 20px;line-height: 1.6;color: var(--shades02);}
.section-video-play .video-share{margin: auto 0 0;}
.section-video-play .video-share .share-title{font-weight: 300; font-size: 16px;margin: 0 0 5px; color: var(--shades02);}
.section-video-play .video-share .share-list{display: flex; margin: 0 -5px;}
.section-video-play .video-share .share-list .share-item{ display: flex; justify-content: center; align-items: center; border-radius: 50%; width: 48px; height: 48px; background-color: var(--shades06); color: var(--shades02); font-size: 16px;margin: 0 5px;}
.section-video-play .video-share .share-list .share-item:hover{color: var(--shades01);background-color: var(--color01);}
.section-video-play .video-container{width: 100%;max-width: calc(70% - 30px);margin: 0 15px;padding-left: 40px;}
.section-video-play .video-container > div{width: 100% !important; height: 480px !important;}
.section-video-play .video-container > div > div{display: flex; justify-content: flex-end;}

.sec-videos{position: relative; padding: 60px 0;}
.video-layout{position: relative; padding: 40px 0;}
.video-layout+.video-layout{margin: 40px 0 0;}
.video-layout-header{display: flex;justify-content: space-between;align-items: center;margin: 0px 0 30px;}
.video-layout-header .video-layout-title{margin: 0;font-size: 24px;color: var(--shades01);line-height: 1;margin-right: auto; font-weight: 400;} 
.video-layout-header .video-layout-title span{color: var(--shades02);}
.video-layout-header .btn-viewAll{font-size: 14px;color: var(--shades01);margin-left: auto;font-weight: 400;background-color: transparent;display: flex;align-items: center;justify-content: center;padding: 0 16px;border-radius: 4px;height: 40px;}
.video-layout-header .btn-viewAll:hover{background-color: var(--shades06);}

.video-layout-list{display: flex;position:relative;padding: 0;overflow: auto;}
.video-layout-list::-webkit-scrollbar{width: 0;height: 0;background-color:transparent;}
.video-layout-list::-webkit-scrollbar-thumb{background-color:transparent;}
.video-layout-list::-webkit-scrollbar-track{background-color:transparent;}
.video-layout-list .swiper{padding: 0 var(--padding);}
.video-layout-list .video-layout-item{display: block; color: var(--shades01);}
.video-layout-list .video-layout-item .video-layout-inner{position: relative;}
.video-layout-list .video-layout-item .video-status{position: absolute;top: 10px;right: 10px;padding: 6px 10px;border-radius: 4px;font-size: 16px;color: var(--shades01);z-index: 1;overflow: hidden;}
.video-layout-list .video-layout-item .video-status:before{ content:''; position: absolute;top: 0;right: 0; width: 100%; height: 100%; background-color: var(--shades08); opacity: 0.6;z-index: -1;}
.video-layout-list .video-layout-item .video-layout-thumbnail{width: 100%; height: 180px; overflow: hidden; border-radius: 4px;position: relative;}
.video-layout-list .video-layout-item .video-layout-thumbnail img{ width: 100%; height: 100%; object-fit: cover;}
.video-layout-list .video-layout-item .video-layout-content{padding: 20px 0;}
.video-layout-list .video-layout-item .video-layout-content .video-title{ font-size: 16px; font-weight: 300; color: var(--shades01);}
.video-layout-list .video-layout-item .video-layout-content .video-text{font-size: 14px; font-weight: 300; color: var(--shades02);}
.video-layout-list.live .video-layout-item .video-layout-thumbnail{height: 400px;}
.video-layout-list.live .video-layout-item .video-layout-thumbnail .video-live{position: absolute;bottom: 20px;left: 20px;background-color: var(--colorLive);padding: 0 8px;padding-left: 20px;height: 24px;line-height: 1;display: flex;justify-content: center;align-items: center;border-radius: 30px;font-size: 12px;font-weight: 500;text-transform: uppercase;}
.video-layout-list.live .video-layout-item .video-layout-thumbnail .video-live:before{content: ''; position: absolute;width: 8px;height: 8px;background-color: var(--shades01);border-radius: 50%;top: 50%;transform: translateY(-50%);left: 8px;}
