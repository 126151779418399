.section-shows{position: relative; padding: 40px 0 100px; background-color: var(--shades08);}
.section-shows .container{display: flex; flex-wrap: wrap;}
.section-shows .shows-content{width: 100%;max-width: calc(30% - 30px);margin: 0 15px; display: flex; flex-direction: column;}
.section-shows .shows-title{font-weight: 300;font-size: 48px;}
.section-shows .shows-text{font-weight: 300;font-size: 16px;margin: 40px 0 20px;line-height: 1.6;color: var(--shades02);}
.section-shows .shows-share{margin: 20px 0 0;}
.section-shows .shows-share .share-title{font-weight: 300; font-size: 16px;margin: 0 0 5px; color: var(--shades02);}
.section-shows .shows-share .share-list{display: flex; margin: 0 -5px;}
.section-shows .shows-share .share-list .share-item{ display: flex; justify-content: center; align-items: center; border-radius: 50%; width: 48px; height: 48px; background-color: var(--shades06); color: var(--shades02); font-size: 16px;margin: 0 5px;}
.section-shows .shows-share .share-list .share-item:hover{color: var(--shades01); background-color: var(--color01);}
.section-shows .shows-container{width: 100%;max-width: calc(70% - 30px);margin: 0 15px;padding-left: 40px;}

.shows-list{position: relative; display: flex; flex-wrap: wrap; margin: 0 -15px;}
.shows-list .shows-item{width: 100%; max-width: calc(33.33% - 30px); margin: 0 15px 60px; transition: all 0.3s ease;}
.shows-list .shows-layout-item{display: block; color: var(--shades01);}
.shows-list .shows-layout-item .shows-layout-inner{position: relative;}
.shows-list .shows-layout-item .shows-status{position: absolute;top: 10px;right: 10px;padding: 6px 10px;border-radius: 4px;font-size: 16px;color: var(--shades01);z-index: 1;overflow: hidden;}
.shows-list .shows-layout-item .shows-status:before{ content:''; position: absolute;top: 0;right: 0; width: 100%; height: 100%; background-color: var(--shades08); opacity: 0.6;z-index: -1;}
.shows-list .shows-layout-item .shows-layout-thumbnail{width: 100%; height: 180px; overflow: hidden; border-radius: 4px;position: relative;border: 1px solid var(--shades06);}
.shows-list .shows-layout-item .shows-layout-thumbnail img{ width: 100%; height: 100%; object-fit: cover;}
.shows-list .shows-layout-item .shows-layout-thumbnail .thumbnail-overlay{position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: var(--color01);display: flex;align-items: center;justify-content: center;font-size: 16px;font-weight: 400; opacity: 0; visibility: hidden; transition: all 0.3s ease;}
.shows-list .shows-layout-item .shows-layout-content{padding: 20px 0;}
.shows-list .shows-layout-item .shows-layout-content .shows-title{ font-size: 16px; font-weight: 300; color: var(--shades01);}
.shows-list .shows-layout-item .shows-layout-content .shows-time{font-size: 14px; font-weight: 300; color: var(--shades02);}
.shows-list.hovered .shows-item{opacity: 0.25;}
.shows-list.hovered .shows-item.active{opacity:1;}
.shows-list.hovered .shows-item.active .shows-layout-item .shows-layout-thumbnail .thumbnail-overlay{opacity: 1; visibility: visible;}